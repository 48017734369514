import axios from 'axios';

/*접속 기기 구분 */
let IorA = navigator.userAgent.toLowerCase();
let mobileOs = "";
if(IorA.indexOf("android") != -1){
    // android 일 때
  mobileOs = "AND";
} else if ( IorA.indexOf("iphone") > -1||IorA.indexOf("ipad") > -1||IorA.indexOf("ipod") > -1|IorA.indexOf("mac") > -1 ) {
    // iphone 일 때
  mobileOs = "IOS";
}else{
  mobileOs = "ETC";
}

const PROXY = window.location.hostname == 'localhost' ? '' : '';
const URL = PROXY;

const httpClient = axios.create({
    baseURL:URL,
    params:{MobileOS: mobileOs, MobileApp: process.env.REACT_APP_NAME, _type: 'json'},
  })

class Visitkorea{
    constructor(lang){
        //한국관광공사 api
        /* API 언어 셋팅*/
        let langService = "EngService"; //영어
        if(lang =="ko"){               //한국어
            langService = "KorService";
        } else if(lang =="jp"){  //일어 日本語
            langService = "JpnService";
        } else if(lang =="chs"){ //중어 간체 中文(简体)
            langService = "ChsService";
        } else if(lang =="cht"){ //중어 번체 中文(繁體)
            langService = "ChtService";
        } else if(lang =="de"){  //독어 Deutsch
            langService = "GerService";
        } else if(lang =="fr"){  //불어 Français
            langService = "FreService";
        } else if(lang =="es"){      //스페인어 서어 Español
            langService = "SpnService";
        } else if(lang =="ru"){      //러시아어 노어 русский
            langService = "RusService";
        } else {
            langService = "EngService";
        }

        this.visitkorea = httpClient;
        this.langService = langService;
    }
  
    //지역기반 관광정보    
    async areaBased(lang, type, numOfRows, pageNo, areaCode, sigunguCode,arrange){
        let contentTypeId = getContentTypeId(lang, type);
        if(arrange == "" || arrange == null){
            arrange = "Q"
        }
        const response = await this.visitkorea.get('/api/areaBased/'+this.langService,{
            params:{
                numOfRows: numOfRows,
                pageNo:pageNo,
                listYN:'Y',
                arrange:arrange,
                contentTypeId:contentTypeId,
                areaCode:areaCode,
                sigunguCode:sigunguCode,
                cat1:'',
                cat2:'',
                cat3:'',
                modifiedtime:""
            }
        });
        
        return response.data.response.body;
    }

    //지역기반 관광정보(디테일 페이지 추천리스트) 
    async areaBasedDetail(lang, contentTypeId, numOfRows, pageNo, areaCode, sigunguCode, cat1, cat2, cat3){
        const response = await this.visitkorea.get('/api/areaBased/'+this.langService,{
            params:{
                numOfRows: numOfRows,
                pageNo:pageNo,
                listYN:'Y',
                arrange:'Q',
                contentTypeId:contentTypeId,
                areaCode:areaCode,
                sigunguCode:sigunguCode,
                cat1:cat1,
                cat2:cat2,
                cat3:cat3,
                modifiedtime:""
            }
        });
        
        return response.data.response.body;
    }
    
    /*공통정보 조회 */
    async detailCommon(contentId){
        const response = await this.visitkorea.get('/api/detail/'+this.langService,{
            params:{
                contentId:contentId,
                contentTypeId:'',
                defaultYN:'Y',
                firstImageYN:'Y',
                areacodeYN:'Y',
                catcodeYN:'Y',
                addrinfoYN:'Y',
                mapinfoYN:'Y',
                overviewYN:'Y',
                //transGuideYN:'Y'
            }   
        });
        return response.data.response.body.items.item[0];
    }

    /*추가이미지정보 조회 */
    async detailImages(contentId,imageYN){
        const response = await this.visitkorea.get('/api/detail/'+this.langService+"/images",{
            params:{
                contentId:contentId,
                imageYN:imageYN,
                subImageYN:'Y'
            }
        });
        return response.data.response.body.items.item;
    }
    
    /*소개정보 조회 */
    async detailIntro(contentId,contentTypeId){
        const response = await this.visitkorea.get('/api/detail/'+this.langService+"/intro",{
            params:{
                contentId:contentId,
                contentTypeId:contentTypeId
            }
        });
        return response.data.response.body.items.item[0];
    }
     /*반복정보 조회 */
     async detailInfo(contentId,contentTypeId){
        const response = await this.visitkorea.get('/api/detail/'+this.langService+"/info",{
            params:{
                contentId:contentId,
                contentTypeId:contentTypeId
            }
        });
        return response.data.response.body.items.item;
    }

     /*키워드 검색 조회 */
     async searchKeyword(lang, type, numOfRows,pageNo,areaCode,sigunguCode,cat1,cat2,cat3,keyword){
        let contentTypeId = getContentTypeId(lang, type)
        const response = await this.visitkorea.get('/api/search/'+this.langService+"/keyword",{
            params:{
                numOfRows: numOfRows,
                pageNo:pageNo,
                listYN:'Y',
                arrange:'Q',
                contentTypeId:contentTypeId,
                areaCode:areaCode,
                sigunguCode:sigunguCode,
                cat1:cat1,
                cat2:cat2,
                cat3:cat3,
                keyword:keyword
            }
        });
        let totalCount = response.data.response.body.totalCount;
        if(totalCount == 0){
            return 0;
        }else {
            return response.data.response.body;
        }
        
    }
     /*숙박 검색 조회 */
     async searchStay(areaCode, sigunguCode, numOfRows, pageNo){
        const response = await this.visitkorea.get('/api/search/'+this.langService+"/stay",{
            params:{
                numOfRows: numOfRows,
                pageNo:pageNo,
                listYN:'Y',
                arrange:'Q',
                areaCode:areaCode,
                sigunguCode:sigunguCode,
                hanOk:"",
                benikia:"",
                goodStay:"",
                modifiedtime:""
            }
        });
        
        return response.data.response.body;
    }
    /*지역 코드 조회 */
    async areaCode(areaCode, numOfRows, pageNo){
        const response = await this.visitkorea.get('/api/code/'+this.langService+"/areaCode",{
            params:{
                numOfRows: numOfRows,
                pageNo:pageNo,
                areaCode:areaCode
            }
        });
    
        return response.data.response.body.items.item;
    }
    /*행사정보 조회(날짜) */
    async searchFestival(numOfRows,pageNo,areaCode,sigunguCode,eventStartDate,eventEndDate){
        const response = await this.visitkorea.get('/api/search/'+this.langService+"/festival",{
            params:{
                numOfRows: numOfRows,
                pageNo:pageNo,
                listYN:'Y',
                arrange:'Q',
                areaCode:areaCode,
                sigunguCode:sigunguCode,
                eventStartDate:eventStartDate,
                eventEndDate:eventEndDate,
                modifiedtime:""
            }
        });
        
        return response.data.response.body;
    }
}
function getContentTypeId(lang, type){
    let contentTypeId = "";
    if(type =="1"){        //관광지
        if(lang == "ko"){
            contentTypeId = "12";
        } else {
            contentTypeId = "76";
        }
    }else if(type =="2"){  //문화시설
        if(lang == "ko"){
            contentTypeId = "14";
        } else {
            contentTypeId = "78";
        }
    } else if(type =="3"){ //음식
        if(lang == "ko"){
            contentTypeId = "39";
        } else {
            contentTypeId = "82";
        }
    } else if(type =="4"){ //숙박
        if(lang == "ko"){
            contentTypeId = "32";
        } else {
            contentTypeId = "80";
        }
    } else if(type =="5"){ //쇼핑
        if(lang == "ko"){
            contentTypeId = "38";
        } else {
            contentTypeId = "79";
        }
    } else if(type =="6"){ //축제/공연
        if(lang == "ko"){
            contentTypeId = "15";
        } else {
            contentTypeId = "85";
        }
    } else if(type =="7"){ //레포츠
        if(lang == "ko"){
            contentTypeId = "28";
        } else {
            contentTypeId = "75";
        }
    } else if(type =="8"){ //여행코스
        if(lang == "ko"){
            contentTypeId = "25";
        } 
    }
    return contentTypeId;
}
export default Visitkorea;