import React from 'react';
import Loader from 'react-loader-spinner';

const Loading = () => {
    return (
            <Loader
                type="Oval"
                color="#3d66ba"
                height={40}
                width={40}
            />
    );
};

export default Loading;